// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
console.clear();
import Vue from 'vue';
import App from './App';

import router from './router/router';
import store from './store/store';
/**
 * sentry
 */
// import * as Sentry from '@sentry/vue';
// import { Integrations } from '@sentry/tracing';
// if (process.env.NODE_ENV === 'production') {
//     Sentry.init({
//         Vue,
//         dsn: 'https://ab09bd7dd92e461a9863629cf1e0e051@o172113.ingest.sentry.io/5689021',
//         integrations: [new Integrations.BrowserTracing()],

//         // Set tracesSampleRate to 1.0 to capture 100%
//         // of transactions for performance monitoring.
//         // We recommend adjusting this value in production
//         tracesSampleRate: 1.0,
//     });
// }
import moment from 'moment';
Vue.prototype.$moment = moment;

window.math = require('mathjs');
Vue.prototype.$math = window.math;
Vue.prototype.$numeral = require('numeral');
Vue.prototype.$math = require('mathjs');
/*textarea內容轉換*/
Vue.prototype.$nl2br = function(text) {
    if (text) {
        return text.replace(/\n/g, '<br/>');
    } else {
        return '';
    }
};
/*清除html標籤*/
Vue.prototype.$strip_tags = function(str) {
    if (typeof str == 'string') {
        return str.replace(/<(?:.|\n)*?>/gm, '');
    } else {
        return '';
    }
};

// import "bootstrap/scss/bootstrap";
// import 'mdbvue/build/css/mdb.css'
import '@fortawesome/fontawesome-free/css/all.min.css';
import imagepicker from './components/imagepicker.vue';
Vue.component('imagepicker', imagepicker);

import cookie from 'vue-cookies';
Vue.prototype.$cookie = cookie;
cookie.config('1d');
// swal 用法 this.$swal.fire({});
import Swal from 'sweetalert2';
Vue.prototype.$swal = Swal.mixin({
    confirmButtonText: '<i class="fa fa-check"></i>&nbsp;確定',
    cancelButtonText: '<i class="fa fa-times"></i>&nbsp;取消',
});

Vue.config.productionTip = false;

// 登入判斷
router.beforeEach((to, from, next) => {
    store.state.user.login_token = cookie.get('login_token');
    store.state.user.id = cookie.get('admin_id');
    store.state.user.name = cookie.get('admin_name');

    store.state.id_store = cookie.get('id_store');

    if (!store.state.user.login_token) {
        store.state.user.login_token = '';
        store.state.user.id = '';
        store.state.user.name = '';
    }
    if (to.path != '/login') {
        if (store.state.user.login_token != '') {
            next();
        } else {
            next({
                path: '/login',
            });
        }
    } else {
        next(); //若點選的是不需要驗證的頁面,則進行正常的路由跳轉
    }
});

import('./scss/style.scss').then(() => {
    console.info('style load');
});
new Vue({
    render: (h) => h(App),
    router,
    store,
    components: {
        App,
    },
}).$mount('#app');
