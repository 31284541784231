import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import layout from '../views/layout.vue';
import empty from '../views/empty.vue';
const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue'),
        props: (route) => ({
            query: route.query,
        }),
    },
    {
        path: '/',
        component: layout,
        children: [
            {
                path: '/',
                component: () => import('../views/index.vue'),
            },
            {
                path: 'account',
                name: '帳號管理',
                component: empty,
                children: [
                    {
                        path: 'changepw',
                        name: '修改登入密碼',
                        component: () => import('../views/account/changepw.vue'),
                    },
                    {
                        path: 'index',
                        name: '列表',
                        component: () => import('../views/account/index.vue'),
                        props: (route) => ({
                            query: route.query,
                        }),
                    },
                    {
                        path: 'add',
                        name: '新增',
                        component: () => import('../views/account/edit.vue'),
                        props: (route) => ({
                            query: route.query,
                        }),
                    },
                    {
                        path: 'edit',
                        name: '維護帳號',
                        component: () => import('../views/account/edit.vue'),
                        props: (route) => ({
                            query: route.query,
                        }),
                    },
                ],
            },
            {
                path: 'data',
                name: '基本資料維護',
                component: empty,
                children: [
                    {
                        path: 'store',
                        name: '分店資料',
                        component: () => import('../views/data/store.vue'),
                    },
                    {
                        path: 'supplier',
                        name: '供應商資料',
                        component: () => import('../views/data/supplier.vue'),
                    },
                ],
            },
            {
                path: 'repair',
                name: '維修估價',
                component: empty,
                children: [
                    {
                        path: 'estimate',
                        name: '新增估價單',
                        component: () => import('../views/repair/estimate.vue'),
                    },
                    {
                        path: 'estimate/list',
                        name: '估價單列表',
                        component: () => import('../views/repair/estimate_list.vue'),
                        props: (route) => ({
                            query: route.query,
                        }),
                    },
                    {
                        path: 'estimate/edit/:id',
                        name: '維護估價單',
                        component: () => import('../views/repair/estimate.vue'),
                    },
                    {
                        path: 'estimate/view/:id',
                        name: '檢視估價單',
                        component: () => import('../views/repair/estimate.vue'),
                    },
                ],
            },
            {
                path: 'orders',
                name: '銷售資料',
                component: empty,
                children: [
                    {
                        path: 'add',
                        name: '新增銷售紀錄',
                        component: () => import('../views/orders/add_order.vue'),
                    },
                    {
                        path: 'edit/:id/:edit',
                        name: '編輯銷售紀錄',
                        component: () => import('../views/orders/add_order.vue'),
                    },
                    {
                        path: 'view/:id',
                        name: '檢視銷售紀錄',
                        component: () => import('../views/orders/add_order.vue'),
                    },
                    {
                        path: 'report',
                        name: '業績報表',
                        component: () => import('../views/orders/report.vue'),
                    },
                ],
            },
            {
                path: 'product',
                component: empty,
                name: '商品資料',
                children: [
                    {
                        path: '',
                        name: '列表',
                        component: () => import('../views/product/list.vue'),
                        props: (route) => ({
                            query: route.query,
                        }),
                    },

                    {
                        path: 'new',
                        name: '新增商品',
                        component: () => import('../views/product/edit.vue'),
                    },
                    {
                        path: 'edit',
                        name: '編輯商品',
                        component: () => import('../views/product/edit.vue'),
                        props: (route) => ({
                            id_product: route.query.id_product,
                        }),
                    },
                    {
                        path: 'class_list',
                        name: '分類列表',
                        component: () => import('../views/product/class_list.vue'),
                        props: (route) => ({
                            query: route.query,
                        }),
                    },
                ],
            },
            {
                path: 'customer',
                name: '客戶資料',
                component: empty,
                children: [
                    {
                        path: '/',
                        name: '列表',
                        props: (route) => ({
                            query: route.query,
                        }),
                        component: () => import('../views/customer/list.vue'),
                    },
                    {
                        path: 'repairLog/:id_customer/:customer_name',
                        name: '維修紀錄',
                        component: () => import('../views/customer/repairLog.vue'),
                    },
                ],
            },
            {
                path: 'operating_cost',
                name: '營業成本維護',
                component: () => import('../views/operating_cost/index.vue'),
            },
            {
                path: 'punch',
                name: '刷卡紀錄',
                component: () => import('../views/punch/list.vue'),
                props: (route) => ({
                    query: route.query,
                }),
            },
            {
                path: 'report',
                name: '報表',
                component: empty,
                children: [
                    {
                        path: '/',
                        name: '列表',
                        component: () => import('../views/report/index.vue'),
                    },
                ],
            },
        ],
    },
    {
        path: '*',
        name: '404',
        component: () => import('../views/404.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
